<template>
  <div class="record--detail--related">
    <h2>{{ $t("single.single_you_also_like") }}</h2>
    <div class="record--detail--related--list">
      <div class="row">
        <div
          class="col-6 col-lg-4 col-xl-2 "
          v-for="(item, index) in relatedRecords"
          :key="index"
        >
          <router-link
            :to="{
              name: 'Record Details',
              params: { slug: item.slug }
            }"
          >
            <SingleItem
              :name="item.title_en"
              :imageName="item.gallery[0].image"
              :hoverFunction="true"
              :relatedItem="true"
            ></SingleItem
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SingleItem from "@/components/masonry/SingleItem";
export default {
  name: "RecordRelated",
  components: {
    SingleItem
  },
  data() {
    return {
      relatedRecords: [],
      singleSampleJson: {
        title_en: "I Club, Visual Identity System",
        imageUrl: "media/hs-00001/hs-00001.jpg",
        sizeType: "portrait"
      }
    };
  },
  props: {
    recordId: Number
  },

  watch: {
    recordId: function(newOne) {
      this.apiRecordRelated();
    }
  },

  methods: {
    async apiRecordRelated() {
      try {
        const recordId = this.recordId;
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/records/related?id=${recordId}`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }
        this.relatedRecords = await response.json();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.record {
  &--detail {
    &--related {
      margin-top: 100px;

      h2 {
        font-family: "Graphik Compact", sans-serif;
        font-weight: 100;
        font-size: 32px;
        margin-bottom: 24px;
      }
      &--list {
        // display: inline-flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        // gap: 20px;
      }
    }
  }
}
</style>
